const index = () =>
    import('@/views/page/index/index')
export const otherRouter = [{
    path: '/',
    name: '/',
    redirect: 'index',
    meta: {
        keepAlive: false //不需要被缓存
    }
},
{
    path: '/index',
    name: '/index',
    component: index,
    meta: {
        keepAlive: false //不需要被缓存
    }
}
]

export default [...otherRouter]