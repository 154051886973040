
import Vue from 'vue'
import App from './App.vue'
import router from './router'
import Vant, { Lazyload } from 'vant';
import 'vant/lib/index.css';
import '@/assets/css/index.less'
import '@vant/touch-emulator';
Vue.config.productionTip = false
Vue.use(Vant);
Vue.use(Lazyload);
new Vue({
  router,
  render: h => h(App)
}).$mount('#app')
